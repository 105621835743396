import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import SEO from '../components/seo';

const PrivateLessons = ({ data, location }) => {
  return (
    <>
      <SEO
        title="Private Lessons"
        description="Want to ensure that you dazzle on the dance floor at that upcoming special event? Consider booking some private lessons with our highly trained teachers!"
        keywords={[`bath`, `private`, `lessons`, `wedding`, `dance`, `learn`]}
        image={data.og.sharp.fixed}
        location={location}
      />
      <ScrollUpButton style={{ zIndex: '99' }} />
      <section>
        <div className="flex flex-col items-center md:block text-justify">
          <div className="mt-5 w-4/5 md:w-1/3 md:ml-8 order-last md:float-right">
            <Image
              className="rounded w-full h-64"
              fluid={data.private.sharp.fluid}
              alt="A newly married couple dancing."
            />
          </div>
          <h1>Private Lessons</h1>
          <p>
            Want to ensure that you dazzle on the dance floor at that upcoming
            special event? Consider booking some private lessons with our highly
            trained teachers to ensure that you, and your partner, are ready to
            impress!
          </p>
          <p>
            Private lessons must be booked in advance to ensure teacher
            availability. Lessons may be booked for Tuesday, Thursday and
            Saturday mornings, as well as Sunday evenings. Some weekday evening
            times also available. They may be booked individually or as a series
            based on your needs and are half an hour or one hour in duration.
            The venue may vary due to availability. Please{' '}
            <Link to="/contact" className="hover:underline text-highlight">
              contact
            </Link>{' '}
            Paula to discuss your requirements.
          </p>
          <div className="my-5 md:mt-10 bg-gray-100 italic p-4 border-l-8 border-highlight max-w-lg mx-auto text-left font-display">
            <p>
              We have helped many wedding couples with their first dance!
              Depending what the couple require we can teach a very simple or
              complicated routine, usually to music of their choice. It is
              advisable to book the lessons early so that the couple have time
              to practice, as the closer they get to the wedding the busier they
              are!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  query {
    private: file(relativePath: { eq: "private-lessons.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    og: file(relativePath: { eq: "private-lessons.jpg" }) {
      sharp: childImageSharp {
        fixed(width: 1200) {
          width
          height
          src
        }
      }
    }
  }
`;

export default PrivateLessons;
